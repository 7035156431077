.spinner-container {
  padding: 10px;

  &.full-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 999;

    &:before {
      content: '';

      background-color: rgba(235, 235, 235, 0.5);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &.light {
    &:before {
      background-color: transparent;
    }
  }
}
