blockquote {
    position: relative;
    padding: 45px 55px;
    background-color: $primary-color;
    border-radius: 10px;
    z-index: 1;
    font-size: 28px;
    font-family: $oldstan;
    font-weight: 700;
    line-height: 1.5;
    color: $white;
    text-align: center;
    letter-spacing: -1px;
    position: relative;

    &::after {
        position: absolute;
        right: 40px;
        left: 40px;
        top: 40px;
        bottom: 40px;
        content: '';
        background-image: url(../img/blog-details/quote-icon.png);
        z-index: -1;
        background-size: contain;
        background-position: right center;
        background-repeat: no-repeat;
    }

    @media #{$lg} {
        font-size: 22px;
        padding: 40px;
    }

    @media #{$sm} {
        padding: 30px 20px;
        font-size: 18px;
    }

    @media #{$tiny} {
        font-size: 16px;
    }

    span {
        display: block;
        font-family: $roboto;
        margin-bottom: 15px;
        font-size: 14px;
        text-transform: none;
    }
}