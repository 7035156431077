.available-slots {
  .container {
    .contact-form {
      padding: 50px;
      padding-top: 20px;

      .time-slots {
        margin-left: -2px;
        margin-right: -2px;

        .time-item {
          background-color: white;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          text-align: center;
          align-items: center;
          border: solid;
          border-color: #bead8e;
          border-width: 1px;
          width: 100%;
          cursor: pointer;
          padding: 5px;

          &.active {
            border: none;
            color: white;
            background-color: #bead8e;
          }

          &:hover {
            border: none;
            color: white;
            background-color: $heading-color;
          }
        }
      }
    }
  }
}