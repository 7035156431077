/*=======  Room List  =======*/
.room-gird-loop {
    .room-list-box {
        .room-img-wrap {
            overflow: hidden;
            width: 100%;
            height: 100%;
            min-height: 310px;

            @media #{$md} {
                min-height: 350px;
            }

            @media #{$xsm} {
                min-height: 250px;
            }

            .room-img {

                height: 100%;
                width: 100%;
                @include transition(0.4s);
                background-size: cover;
                background-position: center;
            }
        }

        &:hover {
            .room-img {
                transform: scale(1.1);
            }
        }

        .room-desc {
            height: 100%;
            background-color: $white;
            font-size: 14px;
            padding: 50px 20px 50px 40px;
            position: relative;

            .row {
                height: 100%;
            }

            @media #{$lsm} {
                padding: 50px 20px 50px 25px;
            }

            @media #{$xsm} {
                text-align: center;
                padding: 50px 30px;
            }

            @media #{$tiny} {
                text-align: center;
                padding: 50px 25px;
            }


            h4 {
                font-size: 24px;
                letter-spacing: -1px;
                margin-bottom: 15px;
            }

            a.book-btn {
                font-size: 16px;
                font-weight: 700;
                color: #bdbdbd;
                position: relative;
                margin-top: 20px;
                padding-top: 20px;

                &:hover {
                    color: $primary-color;
                }
            }
        }
    }
}