.payment-details {
  .payment-methods {
    .card {
      text-align: center;
      cursor: pointer;
      width: 31%;

      @media #{$md} {
        width: 11rem;
        padding: 8px;
      }

      @media #{$sm} {
        width: 100%;

        display: flex;
        justify-content: center;
        flex-direction: row;
      }

      .card-title {
        @media #{$sm} {
          font-size: 16px;
        }
      }

      .card-body {
        @media #{$md} {
          width: 11rem;

          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        @media #{$sm} {
          align-items: center;
          padding: 5px;
          width: 70%;

          .card-img {
            height: 60px;
            width: auto;
          }
        }
      }

      &.selected {
        background-color: #f5f5f5;
        color: $heading-color;
        border: solid 2px $primary-color;
      }

      &:hover {
        background-color: $primary-color;
        color: $white;
      }
    }
  }

  .main-btn.btn-filled {
    box-shadow: none;

    &:hover {
      color: $white;
      background-color: $primary-color;
    }
  }

  .voucher-img-container {
    position: relative;
    text-align: center;

    .voucher-amount {
      top: 41%;
      left: 8%;
      position: absolute;
      color: #bead8e;

      @media #{$lg} {
        color: #bead8e;
        font-size: 50px;
        font-weight: 700;
        top: 40% !important;
      }

      @media #{$md} {
        color: #bead8e;
        font-size: 30px;
        font-weight: 700;
      }

      @media #{$sm} {
        top: 42% !important;
        left: 11% !important;
        color: #bead8e;
        font-size: 16px;
        font-weight: 700;
      }
    }

    .voucher-code {
      top: 80%;
      left: 8%;
      position: absolute;
      color: #02141f;

      @media #{$lg} {
        font-size: 30px;
        font-weight: 700;
        top: 74% !important;
        left: 9% !important;
      }

      @media #{$md} {
        font-size: 20px;
        font-weight: 700;
        top: 75% !important;
      }

      @media #{$sm} {
        font-size: 10px;
        font-weight: 700;
        top: 68% !important;
        left: 11% !important;
      }
    }
  }

  .payment {
    margin-top: 50px;

    button {
      margin-top: 20px;
      width: 100%;
      border: none;
      background-color: $primary-color;
      font-size: 14px;
      font-weight: 700;
      color: $white;
      text-transform: uppercase;
      height: 40px;
      letter-spacing: 2px;
      @include transition(0.3s);

      &:hover {
        border: solid 2px $primary-color;
        background-color: $white;
        color: $black;
      }

      @media #{$lg} {
        font-size: 12px;
        letter-spacing: 1px;
      }
    }

    .sepa-payment {
      .iban-element {
        width: 100%;
        background-color: #f8f8f8;
        padding: 25px;
      }

      .body {
        padding: 20px 0;
      }
    }

    .invoice-payment {
      padding: 0px;

      .body {
        padding: 20px 0;
      }
    }

    .stripe-payment {
      .body {
        padding: 20px 0;
      }

      .StripeElement {
        width: 100%;
        background-color: #f8f8f8;
        padding: 25px;
      }

      .CardField {
        height: 70px !important;
        background-color: #f8f8f8 !important;
        padding: 20px !important;
      }

      .alert {
        margin-top: 20px;
        border: none;
      }
    }
  }
}