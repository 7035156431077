/*=======  BOOKING FORM  =======*/
.booking-form {
  position: relative;
  margin-top: -70px;
  z-index: 2;
  .booking-form-inner {
    background-color: $white;
    padding: 10px 40px 40px;
  }

  .react-datepicker-wrapper {
    width: 100%;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  form {
    input,
    select,
    .nice-select {
      background-color: transparent;
      width: 100%;
      height: 50px;
      font-size: 14px;
      border: none;
      border-bottom: 2px solid $primary-color;
      line-height: 50px;
      color: $black;
    }
    option:first-child {
      color: rgba($color: $text-color, $alpha: 0.5);
    }

    .nice-select {
      border-color: #e9e9e9;
      padding-left: 0;
      &::after {
        display: none;
      }
      &::before {
        left: 0;
      }
    }
    label {
      color: $heading-color;
      font-weight: 700;
      font-family: $roboto;
      display: block;
      margin: 0;
    }

    .inputs-filed {
      position: relative;
      input,
      .nice-select {
        padding: 0 20px;
      }

      .icon {
        position: absolute;
        left: 0;
        bottom: 15px;
        font-size: 14px;
        color: $primary-color;
      }
    }

    .availability-button {
      width: 100%;
      border: none;
      background-color: $primary-color;
      font-size: 14px;
      font-weight: 700;
      color: $white;
      text-transform: uppercase;
      height: 60px;
      letter-spacing: 2px;
      @include transition(0.3s);
      &:hover {
        background-color: $black;
      }

      &.disabled {
        pointer-events: none;
        background-color: $primary-color;
        opacity: 0.5;
      }

      @media #{$lg} {
        font-size: 12px;
        letter-spacing: 1px;
      }
    }
  }

  &.booking-form-dark {
    background-color: $heading-color;
    margin-top: 0;
    .booking-form-inner {
      background-color: transparent;
    }
    form label {
      color: $white;
    }
  }

  &.boxed-layout {
    margin-top: -40px;
    @media #{$md} {
      margin-top: 0;
    }
    @media #{$sm} {
      margin-top: 0;
    }
    form {
      // button {
      //   font-size: 12px;
      //   letter-spacing: 1;
      // }
    }
  }
}

// Datepicker

.react-datepicker-popper {
  .react-datepicker {
    border: none;
    box-shadow: 0 0 8px 2px rgba(228, 228, 228, 0.5);
    background-color: $white;

    display: flex;

    button {
      color: $white;
      width: 20px;
      height: 20px;
      &:hover {
        background-color: transparent;
      }
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__navigation {
      line-height: 1.7rem;

      cursor: pointer;
    }

    .react-datepicker__day--outside-month {
      visibility: hidden;
    }

    .react-datepicker__month-container {
      .react-datepicker__header,
      .react-datepicker__header--has-time-select {
        text-align: center;
        color: $white;
        background-color: $primary-color;
        border-bottom: 1px solid #aeaeae;
        border-top-left-radius: 0.3rem;
        padding-top: 8px;
        position: relative;
        border-radius: 0px;

        .react-datepicker__current-month {
          color: $white;
        }

        .react-datepicker__day-names {
          .react-datepicker__day-name {
            color: $white;
            margin-right: 10px;
          }
        }
      }

      .react-datepicker__month {
        margin: 0px;
        margin-top: 10px;
        padding: 0px 10px 10px;

        .react-datepicker__week,
        .react-datepicker__month-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .react-datepicker__day {
            display: flex;
            justify-content: center;
            align-items: center;

            text-align: center;

            height: 40px;
            width: 40px;
            margin: 0px;

            border-radius: 20px;

            color: $black;

            transition: background-color 0.3s ease, color 0.3s ease;

            outline: none !important;

            &:hover,
            &:focus {
              border-radius: 20px;
            }

            &--selected {
              background-color: $primary-color;
              color: $white;

              &:hover,
              &:focus {
                background-color: gr;
                border-radius: 20px;
              }
            }

            &--keyboard-selected {
              background-color: $primary-color;
              color: $white;

              &:hover,
              &:focus {
                border-radius: 20px;
              }
            }

            &--disabled {
              color: gray;
              opacity: 0.4;
              pointer-events: none;
            }
          }
        }

        .react-datepicker__month-wrapper {
          &:first-child {
            padding-top: 20px;
          }

          .react-datepicker__month-text {
            display: flex;
            justify-content: center;
            align-items: center;

            text-align: center;

            height: 50px;
            width: 50px;

            margin-left: 25px;

            margin-right: 25px;

            border-radius: 50px;

            color: $primary-color;

            transition: background-color 0.3s ease, color 0.3s ease;

            &:hover,
            &:focus {
              border-radius: 50px;
            }
          }

          .react-datepicker__month {
            &--selected {
              background-color: $primary-color;
              color: $white;

              &:hover,
              &:focus {
                background-color: $primary-color;
                border-radius: 50px;
              }
            }

            &--keyboard-selected {
              background-color: $primary-color;
              color: $white;

              &:hover,
              &:focus {
                background-color: $primary-color;
                border-radius: 50px;
              }
            }

            &--disabled {
              color: gray;
            }
          }
        }
      }
    }

    .react-datepicker__time-container {
      min-width: 120px;

      .react-datepicker__header,
      .react-datepicker__header--time {
        height: 75px;
        background-color: $primary-color;

        .react-datepicker-time__header {
          color: $white;
        }
      }

      .react-datepicker__time {
        .react-datepicker__time-box {
          width: 120px;
          overflow: hidden;
        }

        ul.react-datepicker__time-list {
          padding: 12px 0px;

          background-color: $white;

          > li {
            min-height: 40px;

            &.react-datepicker__time-list-item {
              min-width: 90px;

              border-radius: 20px;

              padding: 0px;

              display: flex;
              justify-content: center;
              align-items: center;
            }

            &.react-datepicker__time-list-item--disabled {
              display: none;
            }

            &.react-datepicker__time-list-item--selected {
              background-color: $primary-color;

              &:hover,
              &:focus {
                background-color: $primary-color;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 823px) {
  .react-datepicker-popper
    .react-datepicker
    .react-datepicker__month-container
    .react-datepicker__month
    .react-datepicker__week
    .react-datepicker__day {
    height: 30px;
    width: 30px;
    margin: 0px;
  }

  .booking-form {
    .react-datepicker-wrapper {
      width: 100%;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }

  .room-booking-form {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  .react-datepicker-popper {
    .react-datepicker {
      &.timepicker-select {
        left: -15px !important;
        width: 400px;
      }

      .react-datepicker__time-container {
        min-width: 90px;
        .react-datepicker__time {
          .react-datepicker__time-box {
            width: 90px;
          }

          ul.react-datepicker__time-list {
            padding: 0px;
            height: 190px;
          }
        }
      }
    }

    .react-datepicker {
      .react-datepicker__month-container {
        .react-datepicker__header,
        .react-datepicker__header--custom,
        .react-datepicker__header--has-time-select {
          width: 220px;

          .react-datepicker__day-names {
            .react-datepicker__day-name {
              margin-right: -1px;
            }
          }
        }
      }

      .react-datepicker__month-container {
        .react-datepicker__week,
        .react-datepicker__month-wrapper {
          width: 200px;

          .react-datepicker__day {
            height: 30px;
            width: 30px;
            margin: 0px;

            &--selected {
              background-color: $primary-color;
              color: $white;

              &:hover,
              &:focus {
                background-color: gr;
                border-radius: 10px;
              }
            }
          }
        }
        .react-datepicker__time-container {
          .react-datepicker__time {
            .react-datepicker__time-box {
              width: 80px;
            }
          }
        }
      }
    }
  }
}
