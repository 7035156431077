.container {
  button {
    width: 100%;
    border: none;
    background-color: $primary-color;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    height: 60px;
    letter-spacing: 2px;
    @include transition(0.3s);

    &:hover {
      background-color: $black;
    }

    @media #{$lg} {
      font-size: 12px;
      letter-spacing: 1px;
    }
  }

  .booking-details {
    font-family: $roboto;

    .next-button {
      button {
        width: 100%;
        border: none;
        background-color: $primary-color;
        font-size: 14px;
        font-weight: 700;
        color: $white;
        text-transform: uppercase;
        height: 60px;
        letter-spacing: 2px;
        @include transition(0.3s);

        &:hover {
          background-color: $black;
        }

        @media #{$lg} {
          font-size: 12px;
          letter-spacing: 1px;
        }
      }
    }

    .title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 50px;
      background-color: $white;
      margin-right: 40px;

      @media #{$lg} {
        padding: 40px 30px;
        margin-right: 30px;
      }

      @media #{$lsm} {
        padding: 30px 20px;
        margin-right: 20px;
      }

      @media #{$xsm} {
        padding: 30px;
        margin-right: 20px;
        display: block;
      }

      .title {
        .room-cat {
          text-transform: uppercase;
          padding-bottom: 10px;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 3px;
        }

        h2 {
          font-size: 48px;
          letter-spacing: -1px;

          @media #{$lg} {
            font-size: 42px;
          }

          @media #{$lsm} {
            font-size: 32px;
          }

          @media #{$xsm} {
            font-size: 28px;
          }
        }
      }

      .price {
        font-size: 40px;
        //   color: $primary-color;
        font-weight: 700;
        font-family: $archivo;

        @media #{$lg} {
          font-size: 35px;
        }

        @media #{$lsm} {
          font-size: 30px;
        }

        @media #{$xsm} {
          font-size: 24px;
          margin-top: 15px;
        }

        span {
          font-size: 16px;
        }
      }
    }
  }
}