/*=======  Core Feature  =======*/
.features-loop {
	.feature-box {
		padding: 60px 50px;
		background-color: $white;
		font-size: 14px;
		background-color: $white;
		border: 2px solid $main-bg;
		position: relative;
		margin-top: 30px;

		@media #{$lg} {
			padding: 50px 30px;
		}
		@media #{$md} {
			padding: 50px;
		}
		@media #{$lsm} {
			padding: 35px 25px;
		}
		@media #{$xsm} {
			padding: 50px 30px;
		}

		.icon {
			margin-bottom: 30px;
			font-size: 70px;
			color: $primary-color;
			line-height: 1;
		}
		&:hover {
			.icon i {
				animation: hvr-buzz-out 0.75s linear 1;
			}
		}
		h3 {
			font-size: 30px;
			margin-bottom: 30px;
			letter-spacing: -1px;
			@media #{$lsm} {
				font-size: 24px;
			}
		}
		.count {
			line-height: 1;
			position: absolute;
			right: 0;
			top: -10px;
			font-size: 120px;
			color: $main-bg;
		}
		&.with-hover-img {
			.hover-img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: center;
				@include transition(0.3s);

				visibility: visible;
				opacity: 1;
			}
			&:hover {
				.hover-img {
					opacity: 0;
					visibility: hidden;
				}
			}
		}

		/*=======  Dark Style  =======*/
		&.dark-box {
			background-color: #292929;
			border: none;

			&,
			h3 a {
				color: $white;
			}

			.count {
				color: $heading-color;
			}
		}
	}
}
